.search-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.search-input {
    width: 200px;
    margin: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 6px 10px;
    box-shadow: 2px 2px 10px #aaa;
    outline: none;
    font-size: 16px;
}

.search-input::placeholder {
    color:#aaa
}

@media only screen and (max-width: 600px) {
    .search-bar {
        flex-direction: column;
    }
}