.welcome {
    height: 100%;
    text-align: center;
    padding: 5px;
}

.home {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    .welcome h1 {
        font-size: 25px;
    }
}