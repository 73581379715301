.board-table {
    width: 75%;
    max-width: 600px;
    padding: 0px;
    margin: 10px;
    text-align: left;
}

.board-table th {
    padding: 5px 10px;
}

.board-table tr:nth-child(even) {
    background-color: #eee;
}

.board-table tr:nth-child(100) {
    display: none;
}

.board-header {
    border: 1px solid black;
    border-radius: 5px 5px 0px 0px;
    color: #fff;
    font-weight: 700;
    background-color: #006AD4;
    padding: 5px;
}

.board-container {
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading {
    padding: 15px;
    width: 50%;
    font-size: 30px;
    color:#006AD4;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .board-table {
        font-size: 14px;
    }
}

@media only screen and (max-width: 400px) {
    .board-table {
        font-size: 13px;
    }
}