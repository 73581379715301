.leaderboard-title {
    margin: 10px 10px;
    text-align: center;
}

.leaderboard {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.leaderboard select {
    font-size: 16px;
    text-align: center;
    padding: 5px 10px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 15px;
    box-shadow: 2px 2px 10px #aaa;
}

.leaderboard-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}