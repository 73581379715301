.summoner-profile-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.summoner-info-container {
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
}