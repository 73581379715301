.nav-list {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.nav-list li {
    display: block;
    text-align: center;
    font-weight: 700;
    text-decoration: none;
    float: left;
}

.nav-list a:visited {
    text-decoration: none;  
    color: inherit;
    cursor: pointer;
}

.nav-list a:link {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.nav-list-item {
    padding: 10px 0px;
}

.nav-list-item:hover {
    color: #f8e74a;
}

.nav-bar {
    background-color: #004fB4;
    color: #fff;
    text-shadow: #000;
}

.nav-item {
    padding: 10px 16px;
}

.nav-item-active {
    background-color: #006AD4;
    padding: 10px 16px;
}

@media only screen and (max-width: 600px) {
    .nav-list {
        flex-direction: column;
    }

    .nav-list-item {
        background-color: #006AD4;
        width: 100%;
    }
}