.sub-nav-list {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    align-items: center;
}

.sub-nav-list li {
    display: block;
    text-align: center;
    font-weight: 700;
    padding: 5px 10px;
    text-decoration: none;
    float: left;
}

.sub-nav-list a:visited {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.sub-nav-list a:link {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.sub-nav-item:hover {
    color: #f8e74a;
}

.sub-nav-bar {
    background-color: #006AD4;
    color: #fff;
    text-shadow: #000;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .sub-nav-list {
        flex-direction: column;
    }
}