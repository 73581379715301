html {
    font-family: 'Open Sans', sans-serif;
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
}

#root {
    height: 100%;
}

@font-face {
    font-family: 'Open Sans';
    src: url(./static/fonts/OpenSans-Regular.ttf) format('TrueType');
}